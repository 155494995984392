import React from 'react'

function Notfound() {
    return (
        <div className='mt-5 mb-5 w-75 m-auto'>
            <div className="text-center pt-4 lead bg-light" style={{ height: 85 }}>
                <h1 className='fs-20'>
                    Not Found 404!
                </h1>
                <p>
                    This page is not available for view.
                </p>
            </div>
        </div>
    )
}

export default Notfound