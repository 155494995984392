import axios from "axios";
import { config } from "./Api";

const stitchingOrderApi = async (Comp_Order, id) => {
    console.log("here we go", Comp_Order, id);

    await fetch(`${config.baseurl}/stitching/order/web/create`,
        {
            method: "POST",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                order: Comp_Order,
                productID: id
            })
        }).then(
            (response) => response.json()
        ).catch((err) => {
            // alert("something went wrong. Please try again")
            console.log(err);
        }).then(responseData => {
            console.log(responseData);
            if (responseData.success == true) {
                const orders = localStorage.getItem('orders');
                const newOrders = orders ? JSON.parse(orders) : [];
                localStorage.setItem("orders", JSON.stringify([...newOrders, responseData.response]))

                alert(`Thanks For your order. Please save this order number (${responseData.response.OrderNumber}) for order tracking`)
                return
            }
            else {
                alert("Order is not created")
            }

        })


}

const OrderStatus = async (number) => {
    console.log(number);
    const response = await fetch(`${config.baseurl}/user/order/track/${number}`,
        // {
        //     method: "GET",
        //     headers: {
        //         Accept: 'application/json',
        //         'Content-Type': 'application/json'
        //     },
        // }
    ).then(
        (response) => response.json()
    ).catch((err) => {
        // alert("something went wrong. Please try again")
        console.log(err);
    }).then(responseData => {
        console.log(responseData)
        return responseData

    })

    return response
}


const contactMsg = async (name, email, phone, message) => {
    const response = await fetch(`${config.baseurl}/user/contactUs`,
        {
            method: "POST",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: name,
                email: email,
                phone: phone,
                message: message
            })
        }).then(
            (response) => response.json()
        ).catch((err) => {
            // alert("something went wrong. Please try again")
            console.log(err);
        }).then(responseData => {
            console.log(responseData);
            return responseData
        })
    return response
}

const Subscribe = async (email) => {
    const response = await fetch(`${config.baseurl}/user/newsletter/subscribe`,
        {
            method: "POST",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,

            })
        }).then(
            (response) => response.json()
        ).catch((err) => {
            // alert("something went wrong. Please try again")
            console.log(err);
        }).then(responseData => {
            console.log(responseData);
            return responseData
        })
    return response
}


const searchResults = async (query) => {

    const response = await fetch(`${config.baseurl}/stitching/search-results?query=${query}`,
        {
            method: "GET",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        }
    ).then(
        (response) => response.json()
    ).catch((err) => {
        // alert("something went wrong. Please try again")
        console.log(err);
    }).then(responseData => {
        // console.log(responseData)
        return responseData

    })

    return response
}

// this is for find the all blogs
const callToGetBlogs = async (blog, search) => {

    const response = await fetch(`${config.baseurl}/user/blogs/all?blog=${blog}&search=${search}`,
        {
            method: "GET",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        }
    ).then(
        (response) => response.json()
    ).catch((err) => {
        // alert("something went wrong. Please try again")
        console.log(err);
    }).then(responseData => {
        // console.log(responseData)
        return responseData

    })

    return response
}

export {
    stitchingOrderApi,
    OrderStatus,
    contactMsg,
    Subscribe,
    searchResults,
    callToGetBlogs
}