import React from 'react'
import { callToGetBlogs, searchResults } from '../Configuration/ApiIntegration'
import { useState } from 'react'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

function SearchResults() {

    const [list, setList] = useState([])
    const [search, setSearch] = useState()
    const [empty, setEmpty] = useState(false)
    const [loading, setLoading] = useState(true)
    const [params, setParams] = useSearchParams()
    const query = params.get("query")
    const callToSearchApi = async () => {
        if (!search || search == "") {
            return
        }
        const response = await callToGetBlogs("", search).then((result) => {
            if (result.blogs.length <= 0 || result.blogs == [] || !result) {
                setEmpty(true)
                setLoading(false)
                setList(result.blogs)
            } else {
                setLoading(false)
                setList(result.blogs)
            }
        })

    }

    const setquery = (q) => {
        setSearch(q)
    }
    useEffect(() => {
        setquery(query)
        callToSearchApi()
    }, [search])
    return (
        <div style={{
            textAlign: "center"
        }}>
            <div className="text-center pt-4 lead bg-light fw-bold" style={{ height: 85 }}>
                <h2 className='fw-bold'> Search Results</h2>
            </div>
            {
                loading ?
                    <p className='mt-5 fw-medium fs-18  '>
                        Loading your query ...
                    </p>
                    :
                    empty ?
                        <p className='mt-5 fw-bold fs-18  '>
                            Not matched any data <strong className='text-danger ps-3 pe-3 '> {'\t\t'}  {search}</strong>
                        </p>
                        :
                        <div className='m-auto w-75 row'> {
                            list?.map((item, index) => {

                                return (

                                    <div className='col-sm-12 col-md-6 col-lg-6 mt-2 bg-light shadow '>
                                        <a href={`/pages/design/guide/${item._id}`}>
                                            <img src={item.poster[0]} width={"100%"} height={"250px"} />
                                        </a>
                                        <h6 className='text-center pt-3 text-uppercase fw-bold '>
                                            {item.title}
                                        </h6>
                                        <p className='text-center pt-3  '>
                                            {item.summary}
                                        </p>

                                        <a className="text-center  d-block bg-success text-white icon-link icon-link-hover" href={`/pages/design/guide/${item._id}`}>
                                            Continue Reading {"\t\t"}
                                            <i className="bi bi-arrow-right font-weight-bold fs-20 icon-link icon-link-hover"></i>
                                        </a>
                                    </div>

                                )
                            })
                        }
                        </div>
            }
        </div>
    )
}

export default SearchResults