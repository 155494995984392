
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SearchResults from './Screens/SearchResults';
import Blogs from './Screens/Blogs';
import BlogView from './Screens/BlogView';
import BlogsCtg from './Screens/BlogsCtg';
import Exchange from './Pages/Exchange';
import Contact from './Pages/Contact';
import AboutUs from './Pages/AboutUs';
import FAQ from './Pages/FAQ';
import FashionWiseApp from './Pages/FashionWiseApp';
import PrivacyPolicy from './Pages/PrivacyPolicy'
import TopNavbarrHeader from './Components/Header/TopNavbarrHeader';
import FooterTest from './Components/FooterTest';
import CustomerReview from './Components/Reviews/CustomerReview';
import whatsImgMobile from './Assests/Images/whatsAppMobile.PNG'
import whatsImgWeb from './Assests/Images/WhatsAppWeb.png'
import Notfound from './Pages/Notfound';


function App() {
  return (
    <div className="App" style={{
      backgroundColor: "white",
    }}>

      <TopNavbarrHeader />

      <BrowserRouter  >
        <Routes >

          <Route path='/' element={<Blogs />} />
          <Route path='/design/:type' element={<BlogsCtg />} />
          <Route path='/search-results' element={<SearchResults />} />
          {/* contact , about  , reviews , faq */}
          <Route path='/pages/design/guide/:id' element={<BlogView />} />
          <Route path='/pages/exchange' element={<Exchange />} />
          <Route path='/pages/contact' element={<Contact />} />
          <Route path='/pages/about_us' element={<AboutUs />} />
          <Route path='/pages/customer/reviews' element={<CustomerReview />} />
          <Route path='/pages/faqs' element={<FAQ />} />
          <Route path='/pages/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/pages/fashionwise/app' element={<FashionWiseApp />} />
          <Route path='*' element={<Notfound/>} />

        </Routes>
      </BrowserRouter>
      <FooterTest />



      <div style={{
        position: "absolute",
        right: 5,
        top: "90%",
        position: "fixed",
        zIndex: 1000,
      }}>
        <a href='https://wa.me/923264321272?text=Assalam-o-Aliakum Gee ap kia stitch krwana chaty han . Ladies Ya Gents suit !' >
          <picture >
            <source
              media="(max-width: 768px)"
              srcSet={`${whatsImgMobile} 768w`}
              sizes="768px"
              width={"100%"}
              height={"100%"}
            />
            <source
              srcSet={`${whatsImgWeb} 1280w`}
              sizes="1280px"
            />
            <img src={whatsImgMobile} style={{ width: "100%", height: '50%', borderRadius: 10 }} />
          </picture>
        </a>
      </div>


    </div >
  );
}

export default App;
