import React from 'react'
import { useParams } from 'react-router-dom'
import { setTitleAndDescription } from '../SEO/SeoFile'
import { useState } from 'react'
import { callToGetBlogs } from '../Configuration/ApiIntegration'
import { useEffect } from 'react'

function BlogView() {
    const params = useParams()
    const [article, setArticle] = useState({})
    const [image, setImages] = useState([])
    async function getBlogs() {
        const response = await callToGetBlogs(params.id, "")
        setArticle(response.blogs[0])
        setImages(response.blogs[0].poster)
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        getBlogs()
    }, [])



    setTitleAndDescription(`${article?.title}`,
        `${article?.summary}`,
        `${article?.keywords} `
    )

    // Define the words you want to make bold
    const wordsToBold = article.keywords
    // Function to format the string with bold words
    const MakeWordsBolds = (text, wordsToBold) => {
        return text?.split(' ').map((word, index) => {
            const isBold = wordsToBold.includes(word.toLowerCase());
            return isBold ? <strong key={index}>{word} </strong> : <span key={index}>{word} </span>;
        });
    };
    return (
        <div className='container '>
            <div className="text-center pt-4 fw-bold bg-light " style={{ height: 85 }}>
                <h2 className='fw-bold'>{article.title}</h2>
            </div>
            <div className='m-auto w-75'>
                <p className='mt-3 text-center  fs-5 lh-lg '>
                    {MakeWordsBolds(article.paragraph1, wordsToBold)}
                </p>
                <a href={`https://thefashionwise.com`} target='_blank'>
                    <img src={image[1]} width={"100%"} />
                </a>

                {
                    article.paragraph2?.map((item) => {
                        return (
                            <div>
                                <h3 className='ps-2 pe-2 mt-3 fw-bold'>{item.heading}</h3>
                                <p className='mt-1 text-capitalize fs-5 lh-lg'>
                                    {MakeWordsBolds(item.text, wordsToBold)}
                                </p>
                                <div className='row mt-5 mb-5'>

                                    {item.images.map((img) => {

                                        return (
                                            <div className='col-sm-6 col-md-6 col-lg-4 m-auto'>
                                                {/* /stitching-design/${article.type} */}
                                                <a href={`https://thefashionwise.com`} target='_blank'>
                                                    <img src={img} width={"100%"} />
                                                </a>
                                            </div>
                                        )
                                    }
                                    )}
                                </div>
                            </div>
                        )
                    })
                }
                <p className='mt-3 text-center  fs-5 lh-lg '>
                    {MakeWordsBolds(article.paragraph3, wordsToBold)}
                </p>
                <a href={`https://thefashionwise.com`} target='_blank'>
                    <img src={image[2]} width={"100%"} />
                </a>
                <a>
                    <p className='mt-3 text-center text-capitalize fs-5 fw-bold lh-lg '>
                        <b className='text-uppercase'>
                            You can place order now ! <br />
                        </b>
                        {/* {article.title} */}
                        Share with<br />
                        <a className='text-success' href={`https://wa.me/923264321272?text=${article.title}!   https://thefashionwise.com/stitching-design/${article.type}`}>
                            <bi className="bi bi-whatsapp fs-3" />
                        </a>
                    </p>
                </a>
            </div>
        </div>
    )
}

export default BlogView